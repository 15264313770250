.form-wrap {
  margin: 16px;

  .form-right {
    padding: 24px;
    background-color: white;
    border-radius: 8px;
    // min-width: 992px;
    min-height: calc(100vh - 152px);
  }

  .ant-form-item-label label {
    // font-weight: bold;
  }

  .view-wrap {
    margin: 8px 0;

    .ant-image {
      margin-right: 8px;
      .ant-image-img {
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }

  .operation-button {
    font-size: 16px;
    font-weight: 500;
    // width: 70%;
    // margin: 0 13%;
  }
}
