.index {
  position: relative;
  background-color: rgb(32, 32, 32);

  .bg-gradient-header-1 {
    height: 2px;
    background: linear-gradient(white, rgb(32, 32, 32));
  }

  //竖屏CSS
  @media (orientation: portrait) {
    .bg0 {
      background-image: url(../../../public/assets/bg0.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      min-height: 234vw;
    }
    .bg1 {
      background-image: url(../../../public/assets/bg1.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      min-height: 52vh;
    }
    .bg2 {
      background-image: url(../../../public/assets/bg2.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      min-height: 39vh;
    }
    .bg3 {
      background-image: url(../../../public/assets/bg3.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 1113vw;
      opacity: 0.9;
    }
    .custom {
      position: absolute;
      top: 0;
      left: 8vw;
      width: 26vw;
      height: 45vh;
      opacity: 0;
    }
    .bg-gradient-1-2 {
      height: 4px;
      background: linear-gradient(rgb(32, 32, 32), rgba(211, 62, 49, 0.9));
    }

    .bg-gradient-3-footer {
      height: 2px;
      background: linear-gradient(rgb(32, 32, 32), white);
    }
  }

  //横屏CSS
  @media (orientation: landscape) {
    .bg0 {
      background-image: url(../../../public/assets/bg0.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 68%;
      min-height: 159vw;
      margin: 0 auto;

      // 呼吸灯特效
      @keyframes breathe {
        0% {
          box-shadow: -10px 0 10px -9px rgba(220, 90, 151, 1),
            10px 0 10px -9px rgba(220, 90, 151, 1);
        }

        100% {
          box-shadow: -10px 0 10px -9px rgba(220, 90, 151, 0.2),
            10px 0 10px -9px rgba(220, 90, 151, 0.2);
        }
      }
      box-shadow: -10px 0 10px -9px rgba(220, 90, 151, 1),
        10px 0 10px -9px rgba(220, 90, 151, 1);
      animation-timing-function: ease-in-out;
      animation-name: breathe;
      animation-duration: 3000ms;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }

    .bg1 {
      background-image: url(../../../public/assets/bg1.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 80vh;
    }
    .bg2 {
      background-image: url(../../../public/assets/bg2.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 75vh;
    }
    .bg3 {
      background-image: url(../../../public/assets/bg3.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 557vw;
      opacity: 0.9;
    }
    .custom {
      position: absolute;
      top: 0;
      left: 22vw;
      // width: 12vw;
      height: 90vh;
      width: 17vw;
      opacity: 0;
    }
  }
}

.login-modal {
  position: relative;
  top: 180px;
  .login-button {
    margin-bottom: 8px;
  }
}
