.sider {
  background-color: rgb(255, 255, 255, 0);

  .sider-wrap {
    min-height: calc(100vh - 152px);
    margin: 16px 0 16px 16px;
    border-radius: 8px;
    background-image: url("../../../public/assets/side.jpg");
    background-position-y: -120px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
