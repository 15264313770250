.header {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0;

  .logo {
    height: 64px;
    padding-left: 15px;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  }

  .menu {
    padding-right: 12px;
    flex: 1;
    justify-content: flex-end;
  }
}

.content {
  min-height: calc(100vh - 128px);
}
