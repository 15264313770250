* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
}

@font-face {
  font-family: zhuyuan;
  src: url("../public/assets/MaoKenZhuYuanTi-MaokenZhuyuanTi-2.woff2");
}

body {
  margin: 0;
  font-family: zhuyuan, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
