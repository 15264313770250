.data-wrap {
  margin: 16px;
  background-color: white;
  border-radius: 8px;
  height: calc(100vh - 96px);

  overflow: auto; /* 保留滚动功能 */
  -ms-overflow-style: none; /* 隐藏IE和Edge浏览器的滚动条 */
  scrollbar-width: none; /* 隐藏Firefox浏览器的滚动条 */

  // List
  .image {
    height: 56px;
    width: 56px;
    object-fit: cover;
    border-radius: 8px;
  }

  // Mobile List
  .list-item {
    .item-wrap {
      width: 100%;

      .item-left {
        margin-left: 12px;
        .left-image {
          height: 72px;
          width: 72px;
          object-fit: cover;
          border-radius: 8px;
        }
      }

      .item-right {
        padding: 0 12px;
        .right-row {
          height: 24px;
          margin: 2px 0;
          .row-quantity {
            color: gray;
            margin-right: 11px;
            line-height: 24px;
            font-size: 12px;
          }
          .row-price {
            color: gray;
            line-height: 24px;
            font-size: 12px;
          }
          .row-amount {
            color: #f5222d;
            margin-right: 10px;
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.data-wrap::-webkit-scrollbar {
  display: none; /* 隐藏Webkit浏览器的滚动条 */
}
